<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="d-flex align-center justify-space-between">
                    <h1 class="text-h4" style="flex-grow: 1">
                        Venteliste<span v-if="ready && !loading">&nbsp;({{ personer.length }})</span>
                    </h1>
                    <v-btn color="primary" v-on:click="create">
                        Ny bruker
                        <v-icon right>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-toolbar class="mt-6" color="grey lighten-4" elevation="4">
                    <div class="mr-7">
                        <persons-actions v-model="valgtePersoner" :headers="headers" :alle="alleValgt"></persons-actions>
                    </div>
                    <v-col>
                        <v-text-field v-model="sok" label="Søk etter" append-icon="mdi-magnify" hide-details></v-text-field>
                    </v-col>
                    <div cols="3" class="mr-4">
                        <v-select v-model="rolle" :items="roller" item-text="navn" item-value="id" label="Rolle" hide-details></v-select>
                    </div>
                    <v-col cols="3">
                        <v-select v-model="type" :items="typer" item-text="navn" item-value="id" label="Type" multiple hide-details></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-select v-model="kobling" :items="koblingFilter" item-text="navn" item-value="id" label="Kobling" hide-details></v-select> </v-col
                ></v-toolbar>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        v-model="valgte"
                        :headers="headers"
                        :items="personer"
                        :custom-sort="customSort"
                        sort-by="tittel"
                        no-data-text="Ingen brukere."
                        show-select
                        :items-per-page="-1"
                        hide-default-footer
                        must-sort
                    >
                        <template v-slot:item.fulltNavn="{ item, index }">
                            <td v-on:click="edit(index)" class="v-data-table-item--link">
                                <a class="text-decoration-none"> {{ item.fulltNavn }}</a>
                            </td>
                        </template>
                        <template v-slot:item.opprettet="{ item }"> {{ item.opprettet | format('DD.MM.YYYY') }} </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <person-dialog ref="personDialog" v-model="person" :personer="sortertePersoner" rolle="BRUKER_SORG" v-on:closed="update"> </person-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import PersonDialog from '@/pages/persons/PersonDialog.vue';
import PersonsActions from '@/pages/persons/Actions.vue';

export default {
    name: 'GriefGroups',
    components: {
        PersonDialog,
        PersonsActions,
    },
    data() {
        return {
            headers: [
                { text: 'Navn', value: 'fulltNavn', sortable: true },
                { text: 'Bydel', value: 'bydel', sortable: true },
                { text: 'Opprettet', value: 'opprettet', sortable: true },
            ],

            ready: false,
            loading: false,

            allePersoner: [],
            sortertePersoner: [],
            valgte: [],
            personer: [],
            person: {},

            sok: '',
            kobling: 'uten',
            rolle: 'BRUKER',
            type: [],

            roller: [
                { id: 'BRUKER', navn: 'Bruker' },
                { id: 'FRIVILLIG', navn: 'Frivillig' },
            ],
            typer: [],
            koblingFilter: [
                { id: 'uten', navn: 'Uten kobling' },
                { id: 'ingen', navn: 'Aldri hatt kobling' },
            ],
        };
    },
    computed: {
        valgtePersoner: function () {
            return this.valgte.length ? this.valgte : this.personer;
        },
        alleValgt: function () {
            return !this.valgte.length || this.valgte.length == this.personer.length;
        },
    },
    filters: {
        format: function (value, format) {
            return value.format(format);
        },
    },
    watch: {
        rolle: function (value) {
            this.rolle = value;
            this.update();
        },
        type: function (value) {
            this.type = value;
            if (this.ready) {
                this.filter();
            }
        },
        kobling: function (value) {
            this.kobling = value;
            this.update();
        },
        sok: function (value) {
            this.sok = value;
            if (this.ready) {
                this.filter();
            }
        },
    },
    created: async function () {
        let response = await this.request({
            method: 'get',
            url: '/hjemmetjeneste/type',
        });
        if (response && Array.isArray(response)) {
            this.typer = response;
        }

        await this.update();
    },
    methods: {
        ...mapActions('api', ['request', 'formatPerson']),

        /**
         * update
         */
        update: async function () {
            try {
                this.loading = true;

                this.metoder = [];
                const response = await this.request({
                    method: 'get',
                    url: '/person/' + this.rolle.toLowerCase() + '/hjemme',
                    params: { kobling: this.kobling },
                });

                this.allePersoner = [];
                if (response && Array.isArray(response)) {
                    for (const person of response) {
                        if (person.status == 'AKTIV') {
                            this.allePersoner.push(await this.formatPerson(person));
                        }
                    }
                }

                this.filter();
                this.loading = false;
                this.ready = true;
            } catch (e) {
                console.log(e);
            }
        },

        /**
         * filter
         */
        filter: function () {
            const personer = [];
            const sok = this.sok.toLowerCase();
            for (const person of this.allePersoner) {
                if (
                    (!sok.length || person.sokeString.indexOf(sok) !== -1) &&
                    person.status != 'SLUTTET' &&
                    (!this.type || !this.type.length || (person.hjemmeinformasjon && this.type && this.type.indexOf(person.hjemmeinformasjon.type.id) >= 0))
                ) {
                    personer.push(person);
                }
            }
            this.personer = personer;
        },

        /**
         * customSort
         */
        customSort: function (items, index, isDesc) {
            if (index.length) {
                items.sort((a, b) => {
                    switch (index[0]) {
                        case 'fulltNavn':
                            a = a.etternavn + ' ' + a.fornavn;
                            b = b.etternavn + ' ' + b.fornavn;
                            break;

                        case 'opprettet':
                            a = a.opprettet ? a.opprettet : 0;
                            b = b.opprettet ? b.opprettet : 0;
                            return isDesc[0] ? a - b : b - a;

                        case 'bydel':
                            a = a.bydel || '';
                            b = b.bydel || '';
                            break;

                        default:
                            a = a.id;
                            b = b.id;
                    }

                    if (!isDesc[0]) {
                        return a.localeCompare(b, 'no', { numeric: true, sensitivity: 'base' });
                    } else {
                        return b.localeCompare(a, 'no', { numeric: true, sensitivity: 'base' });
                    }
                });
            }
            this.sortertePersoner = items;
            return items;
        },

        /**
         * edit
         */
        edit: function (index) {
            this.$refs.personDialog.edit(index);
        },

        /**
         * create
         */
        create: function () {
            this.$refs.personDialog.create();
        },
    },
};
</script>
